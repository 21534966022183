import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function TechEnabledOperationsIcon(props) {
  return (
    <SvgIcon {...props} titleAccess="Tech-Enabled Operations" viewBox="0 0 254 254">
<path fill="#489F70" opacity="1.000000" stroke="none" 
	d="
M81.000000,173.970581 
	C71.336472,173.970459 62.172310,174.039154 53.009571,173.954132 
	C39.258038,173.826538 29.057655,163.673035 29.020895,149.864029 
	C28.934853,117.542023 28.924618,85.219475 29.013132,52.897488 
	C29.050238,39.347977 39.212486,29.068584 52.682709,29.025026 
	C85.171219,28.919960 117.660530,28.929842 150.149063,29.034510 
	C163.654053,29.078016 173.888885,39.270393 173.936539,52.807693 
	C174.050949,85.296120 174.036560,117.785522 173.919006,150.273972 
	C173.870529,163.668747 163.496948,173.865524 149.976181,173.930206 
	C127.151245,174.039413 104.325439,173.966766 81.000000,173.970581 
M143.785187,138.241623 
	C133.476547,120.272903 123.167908,102.304184 112.859268,84.335464 
	C112.245811,84.550407 111.632355,84.765350 111.018906,84.980301 
	C110.749321,87.224724 110.387604,89.462585 110.227287,91.714790 
	C109.731308,98.682915 109.178871,105.651413 108.912170,112.629845 
	C108.748314,116.917374 107.454269,120.037300 103.483528,122.386086 
	C97.625679,125.851158 92.148315,129.962387 86.533295,133.833801 
	C83.898926,135.650116 81.321465,137.548965 77.642044,140.179932 
	C99.929100,140.179932 120.725708,140.192673 141.522110,140.132309 
	C142.335114,140.129944 143.146072,139.428162 143.785187,138.241623 
M83.500015,60.846165 
	C75.279953,60.846165 67.059898,60.846165 57.972775,60.846165 
	C68.846939,79.779472 79.184837,97.779060 90.107224,116.796326 
	C91.170357,113.646927 92.370934,111.309883 92.699989,108.855980 
	C93.340424,104.080040 94.171387,99.171539 93.704094,94.438316 
	C92.753395,84.808655 97.020721,79.097427 105.186981,74.677963 
	C111.920998,71.033600 117.950386,66.087234 124.292030,61.717838 
	C124.160408,61.427238 124.028793,61.136642 123.897179,60.846043 
	C110.764793,60.846043 97.632401,60.846043 83.500015,60.846165 
z"/>
<path fill="#F9FEFC" opacity="1.000000" stroke="none" 
	d="
M143.871582,138.646942 
	C143.146072,139.428162 142.335114,140.129944 141.522110,140.132309 
	C120.725708,140.192673 99.929100,140.179932 77.642044,140.179932 
	C81.321465,137.548965 83.898926,135.650116 86.533295,133.833801 
	C92.148315,129.962387 97.625679,125.851158 103.483528,122.386086 
	C107.454269,120.037300 108.748314,116.917374 108.912170,112.629845 
	C109.178871,105.651413 109.731308,98.682915 110.227287,91.714790 
	C110.387604,89.462585 110.749321,87.224724 111.018906,84.980301 
	C111.632355,84.765350 112.245811,84.550407 112.859268,84.335464 
	C123.167908,102.304184 133.476547,120.272903 143.871582,138.646942 
z"/>
<path fill="#FAFEFB" opacity="1.000000" stroke="none" 
	d="
M84.000015,60.846104 
	C97.632401,60.846043 110.764793,60.846043 123.897179,60.846043 
	C124.028793,61.136642 124.160408,61.427238 124.292030,61.717838 
	C117.950386,66.087234 111.920998,71.033600 105.186981,74.677963 
	C97.020721,79.097427 92.753395,84.808655 93.704094,94.438316 
	C94.171387,99.171539 93.340424,104.080040 92.699989,108.855980 
	C92.370934,111.309883 91.170357,113.646927 90.107224,116.796326 
	C79.184837,97.779060 68.846939,79.779472 57.972775,60.846165 
	C67.059898,60.846165 75.279953,60.846165 84.000015,60.846104 
z"/>
    </SvgIcon>
  );
}
